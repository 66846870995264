import React, { useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Warning from "./Warning.js";
import ChatSelect from "./ChatSelect.js";
import ChatRoom from "./ChatRoom.js";
import ChatPanel from "./ChatPanel.js";
import SigilsIcon from "./assets/SigilsIcon.png";
import connectButton from "./assets/Connect.png";
import connectButtonHover from "./assets/ConnectHover.png";
import connectButtonFocus from "./assets/ConnectFocus.png";
import connectingButton from "./assets/Connecting.png";
import connectingButtonHover from "./assets/ConnectingHover.png";
import connectingButtonFocus from "./assets/ConnectingFocus.png";
import disconnectButton from "./assets/Disconnect.png";
import disconnectButtonHover from "./assets/DisconnectHover.png";
import disconnectButtonFocus from "./assets/DisconnectFocus.png";
import googleButton from "./assets/Google.png";
import googleButtonHover from "./assets/GoogleHover.png";
import googleButtonFocus from "./assets/GooglePressed.png";
import boyOutline from "./assets/BoyOutline.png";
import girlOutline from "./assets/GirlOutline.png";
import PulseLoader from "react-spinners/PulseLoader";
import firebase from "./Firebase.js";

import { useAuthState } from "react-firebase-hooks/auth";

import { useDocumentData } from "react-firebase-hooks/firestore";

import { isMobile } from "react-device-detect";

const auth = firebase.auth();
const functions = firebase.functions();
const verify = functions.httpsCallable("verifySignedMessage");
const getNonce = functions.httpsCallable("getNonce");
const firestore = firebase.firestore();
const analytics = firebase.analytics();

const toHex = (stringToConvert) => {
  return stringToConvert
    .split("")
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("");
};

function App() {
  const [user] = useAuthState(auth);
  const [menuUp, setMenuUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warned, setWarned] = useState(false);
  const [noWallet, setNoWallet] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  if (screenHeight == 0) {
    setScreenHeight(window.innerHeight);
  }

  let selectedAccountTemp;

  const metamask = async () => {
    let provider = window.ethereum;

    if (typeof provider !== "undefined") {
      setNoWallet(false);
      setLoading(true);
      provider
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          selectedAccountTemp = accounts[0];
          getNonce({ address: selectedAccountTemp })
            .then((result) => {
              provider
                .request({
                  method: "personal_sign",
                  params: [
                    `0x${toHex(String(result.data.nonce))}`,
                    selectedAccountTemp,
                  ],
                })
                .then((tokenTemp) => {
                  verify({
                    signature: tokenTemp,
                    address: selectedAccountTemp,
                  })
                    .then((result) => {
                      auth.signInWithCustomToken(result.data.token);
                    })
                    .catch((err) => {
                      setLoading(false);
                    });
                })
                .catch((err) => {
                  setLoading(false);
                });
            })
            .catch((err) => {
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setNoWallet(true);
    }
  };

  return (
    <div
      className="font-pixel bg-sigilsBG bg-cover bg-top overflow-y-scroll scrollbar-hide imagePixelated"
      id="background"
      style={{ height: screenHeight }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <NavBar />
                {user ? <ChatSelect /> : <LoggedOutInfo />}
              </div>
            }
          />
          <Route
            path="/bio/:nftId"
            element={
              <div>
                {!isMobile && <NavBar />}
                <ChatPanel />
              </div>
            }
          />
          <Route
            path="/chat/:nftId"
            element={
              <div>
                {!isMobile && <NavBar />}
                {warned ? <ChatRoom /> : <Warning setWarned={setWarned} />}
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );

  function NavBar() {
    return (
      <div className={`flex transition-colors justify-between `}>
        <a href="https://sigils.ai/">
          {" "}
          <img
            draggable="false"
            src={SigilsIcon}
            className="h-20 sm:h-28 headshot"
          />
        </a>

        <div className="flex justify-end">
          <div className="mr-4 mt-2">
            {user && !menuUp && <Profile />}
            {user && menuUp && <Menu />}
          </div>
          {user ? <DisconnectWallet /> : <ConnectWallet />}
        </div>
      </div>
    );
  }

  function Profile() {
    const userDocRef = firestore
      .collection("users")
      .doc(auth.currentUser ? auth.currentUser.uid : "a");
    const [userData] = useDocumentData(userDocRef);
    return (
      <img
        className="sm:right-52 w-14 h-14 sm:w-20 sm:h-20 rounded-full cursor-pointer headshot"
        onClick={() => {
          setMenuUp(true);
        }}
        src={userData && userData.image}
        draggable="false"
      />
    );
  }

  function Menu() {
    const userDocRef = firestore
      .collection("users")
      .doc(auth.currentUser ? auth.currentUser.uid : "a");
    const [userData] = useDocumentData(userDocRef);
    const [emailForm, setEmailForm] = useState("");
    const [nameForm, setNameForm] = useState("");
    const [promoCheck, setPromoCheck] = useState(0);

    const promoChange = (e) => {
      if (e.target.checked) {
        setPromoCheck(1);
      } else {
        setPromoCheck(2);
      }
    };
    const updatePreferences = async (e) => {
      var batch = firestore.batch();
      if (emailForm && emailForm.trim() != "") {
        batch.update(userDocRef, {
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          email: emailForm,
        });
      }
      if (nameForm && nameForm.trim() != "") {
        batch.update(userDocRef, {
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          name: nameForm,
        });
      }
      if (promoCheck === 1) {
        batch.update(userDocRef, {
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          receivePromos: true,
        });
      } else if (promoCheck === 2) {
        batch.update(userDocRef, {
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          receivePromos: false,
        });
      }

      await batch.commit().catch((e) => {
        if (e.code === "permission-denied") {
          console.log("Permission Denied");
        }
      });
    };

    return (
      <div className="border-2 border-black bg-gray-200 w-96 p-6 absolute top-0 right-0 z-10">
        <form
          onSubmit={(e) => {
            setMenuUp(false);
            updatePreferences();
          }}
        >
          <p className="text-2xl mb-2">Preferences</p>
          <img
            className="w-20 h-20 rounded-full content-center m-auto headshot"
            src={userData && userData.image}
            draggable="false"
          />
          <p className="mt-1 text-lg">Nickname:</p>
          <input
            className="border-2 mb-1 border-black w-full  mr-2 px-1 text-xl"
            value={nameForm}
            onChange={(e) => setNameForm(e.target.value)}
            maxLength={20}
            placeholder={userData && userData.name}
          />
          <p className="text-lg">Email:</p>
          <input
            className="border-2 mb-1 border-black w-full  mr-2 px-1 text-xl"
            value={emailForm}
            onChange={(e) => setEmailForm(e.target.value)}
            maxLength={50}
            placeholder={userData && userData.email}
            type="email"
          />
          <label className="mb-4 ">
            <input
              type="checkbox"
              checked={
                promoCheck === 0
                  ? userData && userData.receivePromos
                  : promoCheck === 1
                  ? true
                  : false
              }
              onChange={promoChange}
            />{" "}
            I want to receive updates from Sigils
          </label>

          <input
            className="flex-none border-2 text-2xl w-full h-11 bg-blue-500 text-lg cursor-pointer hover:bg-blue-400"
            onMouseDown={(e) => {
              e.currentTarget.style["background-color"] = "#2563eb";
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style["background-color"] = "#60a5fa";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style["background-color"] = "#3b82f6";
            }}
            onTouchStart={(e) => {
              e.currentTarget.style["background-color"] = "#60a5fa";
            }}
            onTouchEnd={(e) => {
              e.currentTarget.style["background-color"] = "#3b82f6";
            }}
            type="submit"
            value="Save"
          ></input>
          <button
            className="flex-none border-2 text-2xl w-full h-11 mt-1 bg-red-500 text-lg"
            onClick={() => setMenuUp(false)}
            onMouseDown={(e) => {
              e.currentTarget.style["background-color"] = "#dc2626";
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style["background-color"] = "#f87171";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style["background-color"] = "#ef4444";
            }}
            onTouchStart={(e) => {
              e.currentTarget.style["background-color"] = "#f87171";
            }}
            onTouchEnd={(e) => {
              e.currentTarget.style["background-color"] = "#ef4444";
            }}
          >
            Close
          </button>
        </form>
      </div>
    );
  }

  function ConnectWallet() {
    if (loading) {
      return (
        <div
          className="relative cursor-pointer unselectable"
          onClick={() => metamask()}
          onMouseDown={(e) => {
            e.currentTarget.querySelector("img").src = connectingButtonFocus;
          }}
          onMouseEnter={(e) => {
            e.currentTarget.querySelector("img").src = connectingButtonHover;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.querySelector("img").src = connectingButton;
          }}
          onTouchStart={(e) => {
            e.currentTarget.querySelector("img").src = connectingButtonHover;
          }}
          onTouchEnd={(e) => {
            e.currentTarget.querySelector("img").src = connectingButton;
          }}
        >
          <p className="text-lg sm:text-xl  absolute right-7 top-6.2 sm:right-10.7 sm:top-8.2 text-gray-900">
            Connecting
            <PulseLoader
              color="#111827"
              loading={loading}
              size={3}
              speedMultiplier={1}
            />
          </p>
          <img
            className="w-36 sm:w-44"
            src={isMobile ? connectingButton : connectingButtonHover}
            draggable="false"
          />
        </div>
      );
    } else {
      return (
        <div className="">
          <div
            className="relative cursor-pointer unselectable"
            onClick={() => metamask()}
            onMouseDown={(e) => {
              e.currentTarget.querySelector("img").src = connectButtonFocus;
            }}
            onMouseEnter={(e) => {
              e.currentTarget.querySelector("img").src = connectButtonHover;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.querySelector("img").src = connectButton;
            }}
            onTouchStart={(e) => {
              e.currentTarget.querySelector("img").src = connectButtonHover;
            }}
            onTouchEnd={(e) => {
              e.currentTarget.querySelector("img").src = connectButton;
            }}
          >
            <p className=" text-lg sm:text-xl  absolute right-5 top-6.2 sm:right-8 sm:top-8.2 text-gray-900">
              Connect Wallet
            </p>
            <img
              className="w-36 sm:w-44"
              src={connectButton}
              draggable="false"
            />
          </div>
        </div>
      );
    }
  }

  function DisconnectWallet() {
    let navigate = useNavigate();
    return (
      <div className="">
        <div
          className="relative cursor-pointer  mb-4 unselectable"
          onClick={() => {
            auth.signOut();
            setLoading(false);
            navigate("/");
          }}
          onMouseDown={(e) => {
            e.currentTarget.querySelector("img").src = disconnectButtonFocus;
          }}
          onMouseEnter={(e) => {
            e.currentTarget.querySelector("img").src = disconnectButtonHover;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.querySelector("img").src = disconnectButton;
          }}
          onTouchStart={(e) => {
            e.currentTarget.querySelector("img").src = disconnectButtonHover;
          }}
          onTouchEnd={(e) => {
            e.currentTarget.querySelector("img").src = disconnectButton;
          }}
        >
          <p className=" text-lg sm:text-xl  absolute right-8.5 top-6.2 sm:right-12 sm:top-8.2 text-gray-900">
            Disconnect
          </p>
          <img
            className="w-36 sm:w-44"
            src={disconnectButton}
            draggable="false"
          />
        </div>
      </div>
    );
  }

  function LoggedOutInfo() {
    const signInWithGoogle = () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      auth.signInWithPopup(provider);
    };
    return (
      <div className="flex flex-col items-center">
        <p className="font-sharp text-6xl sm:text-8xl text-white mb-6 sm:mb-16">
          Sigils
        </p>
        <section className="flex flex-col items-center relative  my-5  border-yellow-200 bg-gray-100 rounded-xl w-80 sm:w-96  shadow-2xl">
          <p className="text-gray-900  text-xlh sm:text-2xl ">
            Sign In to Talk to our Characters
          </p>
          {noWallet && (
            <p className="text-red-500 text-center text-3xl ">
              No Wallet Found
            </p>
          )}

          <div className="flex">
            {" "}
            <img className="w-44 ml-7 sm:ml-8" src={boyOutline} />
            <img className="w-44" src={girlOutline} />
          </div>
          <div className="mt-1">
            <ConnectWallet />
          </div>
          <p>OR</p>
          <img
            src={googleButton}
            className="w-36 sm:w-44 ml-1 sm:ml-2 mb-3 cursor-pointer pr-1 rounded-br-lg headshot"
            onClick={signInWithGoogle}
            draggable="false"
            onMouseDown={(e) => {
              if (!isMobile) {
                e.currentTarget.src = googleButtonFocus;
              }
            }}
            onMouseEnter={(e) => {
              e.currentTarget.src = googleButtonHover;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = googleButton;
            }}
            onTouchStart={(e) => {
              e.currentTarget.src = googleButtonHover;
            }}
            onTouchEnd={(e) => {
              e.currentTarget.src = googleButton;
            }}
          />
        </section>
      </div>
    );
  }
}

export default App;
