import { isMobile } from "react-device-detect";
function WhitelistButton({ textSize }) {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSf3uBU8bJQSrIK9u9ED6U07qQuiICZtBQ5Q-0efssvzgzAqeg/viewform?usp=sf_link"
      target="_blank"
      class="mb-3 border-3 border-white text-white font-bold py-2 px-6 rounded-full"
      style={{ borderColor: "#fafafa", fontSize: textSize }}
      onMouseDown={(e) => {
        if (!isMobile) {
          e.currentTarget.style.borderColor = "#e2e8f0";
          e.currentTarget.style.backgroundColor = "#e2e8f0";
          e.currentTarget.style.color = "#6DD8FF";
        }
      }}
      onMouseEnter={(e) => {
        if (!isMobile) {
          e.currentTarget.style.borderColor = "#fafafa";
          e.currentTarget.style.color = "#6DD8FF";
          e.currentTarget.style.backgroundColor = "#fafafa";
        }
      }}
      onMouseLeave={(e) => {
        if (!isMobile) {
          e.currentTarget.style.borderColor = "#fafafa";
          e.currentTarget.style.backgroundColor = "transparent";
          e.currentTarget.style.color = "#fafafa";
        }
      }}
      onTouchStart={(e) => {
        e.currentTarget.style.borderColor = "#fafafa";
        e.currentTarget.style.backgroundColor = "#fafafa";
        e.currentTarget.style.color = "#6DD8FF";
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style.borderColor = "#fafafa";
        e.currentTarget.style.backgroundColor = "transparent";
        e.currentTarget.style.color = "#fafafa";
      }}
    >
      Join the Whitelist
    </a>
  );
}

export default WhitelistButton;
