import React from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

import firebase from "./Firebase.js";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import chatButton from "./assets/ChatButton.png";
import chatButtonHover from "./assets/ChatButtonHover.png";
import chatButtonFocus from "./assets/ChatButtonFocus.png";

import WhitelistButton from "./WhitelistButton";
import Menu from "./FirstMenu.js";
import InfoButton from "./InfoButton.js";
import ClipLoader from "react-spinners/ClipLoader";
import { isMobile } from "react-device-detect";

const auth = firebase.auth();
const firestore = firebase.firestore();

function ChatSelect() {
  const navigate = useNavigate();

  const getMaxHeight = () => {
    if (window.innerWidth > 625) {
      return window.innerHeight * 0.867;
    } else {
      return window.innerHeight * 0.9;
    }
  };

  const uid = auth.currentUser ? auth.currentUser.uid : "a";

  const userDocRef = firestore.collection("users").doc(uid);
  const userNftRef = userDocRef.collection("nfts");
  const [userDocument] = useDocumentData(userDocRef);
  const [userNfts] = useCollectionData(userNftRef, { idField: "id" });

  if (userDocument === null || userDocument === undefined) {
    return (
      <div className="flex flex-col items-center mt-20">
        <ClipLoader size={60} color="#2499FF" />
      </div>
    );
  } else if (userDocument.name == null) {
    return <Menu />;
  }

  const characterCards = [];
  if (userNfts != null) {
    userNfts.forEach((doc) => {
      characterCards.push(
        <section
          className="flex flex-col items-center relative   mt-5 mb-12 border-yellow-200 bg-gray-100 rounded-xl w-80  shadow-2xl"
          key={doc.name}
        >
          <div className="h-101 flex flex-col justify-end">
            {" "}
            {doc.name == "Dr. Talos" ? (
              <img className="flex-none  h-88 w-44 px-2" src={doc.image} />
            ) : (
              <img className="flex-none  w-44 px-2" src={doc.image} />
            )}{" "}
          </div>
          <p className="mb-4 mt-2 text-6xl text-center w-full">{doc.name}</p>
          <div className="absolute right-4 top-2">
            <InfoButton nftId={doc.id} />
          </div>
          <div
            className="relative cursor-pointer mb-4 unselectable"
            onClick={(e) => {
              navigate(`/chat/${doc.id}`);
              firebase.analytics().logEvent("select_content", {
                content_type: "View Chat",
                item_id: doc.id,
              });
            }}
            onMouseDown={(e) => {
              e.currentTarget.querySelector("img").src = chatButtonFocus;
            }}
            onMouseEnter={(e) => {
              e.currentTarget.querySelector("img").src = chatButtonHover;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.querySelector("img").src = chatButton;
            }}
            onTouchStart={(e) => {
              e.currentTarget.querySelector("img").src = chatButtonHover;
            }}
            onTouchEnd={(e) => {
              e.currentTarget.querySelector("img").src = chatButton;
            }}
          >
            <img className="w-52" src={chatButton} draggable="false" />
            <p className="text-3xl absolute right-12.2 top-8.5 text-gray-900">
              Say Hello
            </p>
          </div>
        </section>
      );
    });
  }
  return (
    <div
      className="overflow-y-scroll scrollbar-hide"
      style={{ "max-height": getMaxHeight() }}
    >
      <div className="flex items-center flex-col">
        <div className="top-7 absolute">
          {!isMobile && <WhitelistButton textSize="25px" />}
        </div>
        <p className="font-sharp mb-3 text-6xl sm:text-9xl text-white">
          Sigils
        </p>
        {isMobile && <WhitelistButton textSize="16px" />}
      </div>

      {userNfts === undefined || characterCards.length === 3 ? (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 place-items-center lg:mx-20">
          {characterCards}
        </div>
      ) : (
        <div className="flex flex-col items-center mt-20">
          <p className="text-3xl mb-10 text-center">
            Your characters are getting ready to meet you
          </p>

          <ClipLoader size={60} color="#2499FF" />
        </div>
      )}
    </div>
  );
}

export default ChatSelect;
