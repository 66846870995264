const MESSAGE_STYLE =
  "break-words rounded-2xl sm:max-w-xlg bg-gray-300 inline-block text-xl sm:text-xzs ml-1 mr-1 leading-little py-0.5 ";
function ChatMessage(props) {
  let messageStyle;
  if (props.author == "H") {
    messageStyle = MESSAGE_STYLE + "user" + props.randomBorder;
    return (
      <div className="flex justify-end py-1">
        <div className={messageStyle}>{props.message}</div>
        <img
          className="w-9 h-9 sm:w-12 sm:h-12 rounded-full ml-1 mr-1 headshot"
          src={props.userImage}
          onClick={() => {
            //props.onDelete(id, "owner", text);
          }}
        />
      </div>
    );
  } else {
    messageStyle = MESSAGE_STYLE + "bot" + props.randomBorder;
    return (
      <div className="flex justify-start py-1">
        <img
          className="w-9 h-9 sm:w-12 sm:h-12 rounded-full ml-1 mr-1 headshot"
          src={props.nftImage}
          onClick={() => {
            //props.onDelete(id, props.nftName, text);
          }}
        />
        <p className={messageStyle}>{props.message}</p>
      </div>
    );
  }
}

export default ChatMessage;
