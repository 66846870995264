import twitterLogo from "./assets/Twitter.png";
import tiktokLogo from "./assets/Tiktok.png";
import discordLogo from "./assets/Discord.png";
import { isMobile } from "react-device-detect";
function SharePopup(props) {
  return (
    <div
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex items-center">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                <p
                  class="text-4xl sm:text-5xl  font-medium text-gray-900 mb-2"
                  id="modal-title"
                >
                  Interested in joining our whitelist?
                </p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf3uBU8bJQSrIK9u9ED6U07qQuiICZtBQ5Q-0efssvzgzAqeg/viewform?usp=sf_link"
                  target="_blank"
                  class="py-1 border-3  font-bold py-2 px-6 rounded-full"
                  style={{
                    borderColor: "#6DD8FF",
                    fontSize: "20px",
                    color: "#6DD8FF",
                  }}
                  onMouseDown={(e) => {
                    if (!isMobile) {
                      e.currentTarget.style.borderColor = "#0ea5e9";
                      e.currentTarget.style.backgroundColor = "#0ea5e9";
                      e.currentTarget.style.color = "#fafafa";
                    }
                  }}
                  onMouseEnter={(e) => {
                    if (!isMobile) {
                      e.currentTarget.style.borderColor = "#6DD8FF";
                      e.currentTarget.style.color = "#fafafa";
                      e.currentTarget.style.backgroundColor = "#6DD8FF";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (!isMobile) {
                      e.currentTarget.style.borderColor = "#6DD8FF";
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.color = "#6DD8FF";
                    }
                  }}
                  onTouchStart={(e) => {
                    e.currentTarget.style.borderColor = "#fafafa";
                    e.currentTarget.style.backgroundColor = "#6DD8FF";
                    e.currentTarget.style.color = "#fafafa";
                  }}
                  onTouchEnd={(e) => {
                    e.currentTarget.style.borderColor = "#6DD8FF";
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color = "#6DD8FF";
                  }}
                >
                  Join the Whitelist
                </a>

                <p class="mt-2 text-2xl sm:text-3xl text-gray-900">
                  Join our whitelist by posting a screenshot of your convo{" "}
                  {/* {props.name} */}
                </p>

                <p className="text-2xl text-gray-500">
                  Post a picture or video with
                </p>
                <p className="text-2xl text-gray-500">
                  <span className="text-blue-500">#NFTxAI</span> and tag
                  <span className="text-blue-500"> @SigilsNFT</span>
                </p>
                <div className="flex justify-evenly">
                  <a href="https://twitter.com/SigilsNFT" target="_blank">
                    <img className="w-18 h-18 headshot" src={twitterLogo}></img>
                  </a>
                  <a href="https://discord.gg/btXFWXZZDF" target="_blank">
                    <img className="mt-1 h-15 headshot" src={discordLogo} />
                  </a>
                  {/* <a href="https://www.tiktok.com/@sigilsnft" target="_blank">
                    <img className="w-17 h-17 headshot" src={tiktokLogo}></img>
                  </a> */}
                </div>
                <p className="text-xl">
                  Join our discord to claim your whitelist spot, more giveaways,
                  contests/puzzles, and the latest info
                </p>
                <div className="flex justify-around">
                  {/* <a href="https://discord.gg/btXFWXZZDF" target="_blank">
                    <img className="w-25 headshot" src={discordLogo} />
                  </a> */}
                </div>
                <p className="text-red-600 text-4xl">500 Spots to Giveaway</p>
              </div>
            </div>
          </div>
          <div class="bg-gray-200 py-3  flex justify-around">
            <button
              type="button"
              class=" rounded-md text-sm sm:text-lg px-4 py-2 bg-green-500 text-gray-900   w-auto "
              onClick={props.close}
              onMouseDown={(e) => {
                e.currentTarget.style["background-color"] = "#059669";
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style["background-color"] = "#34d399";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style["background-color"] = "#10b981";
              }}
              onTouchStart={(e) => {
                e.currentTarget.style["background-color"] = "#34d399";
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style["background-color"] = "#10b981";
              }}
            >
              Back to Chat with {props.name}
            </button>
            <button
              type="button"
              class="rounded-md text-sm sm:text-lg px-4 py-2 bg-blue-500 text-gray-900   w-auto "
              onClick={props.navigateHome}
              onMouseDown={(e) => {
                e.currentTarget.style["background-color"] = "#2563eb";
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style["background-color"] = "#60a5fa";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style["background-color"] = "#3b82f6";
              }}
              onTouchStart={(e) => {
                e.currentTarget.style["background-color"] = "#60a5fa";
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style["background-color"] = "#3b82f6";
              }}
            >
              Try Another Character
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharePopup;
