import { useNavigate } from "react-router-dom";
function BackButton() {
  let navigate = useNavigate();
  return (
    <div
      className="flex items-center cursor-pointer unselectable"
      onClick={() => navigate("/")}
      onMouseEnter={(e) => {
        e.currentTarget.style = "color: #374151;";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style = "color: black;";
      }}
      onTouchStart={(e) => {
        e.currentTarget.style = "color: #374151;";
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style = "color: black;";
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <p className="text-2xl">Back</p>
    </div>
  );
}

export default BackButton;
