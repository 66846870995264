import React, { useRef, useState, useEffect } from "react";
import "./App.css";

import firebase from "./Firebase.js";
import sendButton from "./assets/SendButton.png";
import sendButtonFocus from "./assets/SendButtonFocus.png";
import sendButtonHover from "./assets/SendButtonHover.png";

import TextareaAutosize from "react-textarea-autosize";
import BackButton from "./BackButton.js";
import ChatMessage from "./ChatMessage.js";
import SharePopup from "./SharePopup.js";
import SyncLoader from "react-spinners/SyncLoader";
import BeatLoader from "react-spinners/BeatLoader";
import InfiniteScroll from "react-infinite-scroll-component";

import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { toSvg } from "html-to-image";
import InfoButton from "./InfoButton";

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const sendMessage = functions.httpsCallable("sendMessage");
const MESSAGE_LIMIT = 5;

function ChatRoom(props) {
  let params = useParams();
  let navigate = useNavigate();
  let nftId = params.nftId;

  const uid = auth.currentUser ? auth.currentUser.uid : "a";
  const bottomRef = useRef();

  const userDocRef = firestore.collection("users").doc(uid);
  const userNftDocRef = userDocRef.collection("nfts").doc(nftId);
  const [userNftData] = useDocumentData(userNftDocRef);
  // const userNftMessagesRef = userNftDocRef.collection("messages");
  // const [messages] = useCollectionData(
  //   userNftDocRef.collection("messages").orderBy("createdAt", "desc").limit(10),
  //   { idField: "id" }
  // );
  const [userData] = useDocumentData(userDocRef);
  const [showPopup, setShowPopup] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [chatHeight, setChatHeight] = useState(0);
  const [chatWidth, setChatWidth] = useState(0);
  const [textAreaSize, setTextAreaSize] = useState(36);
  const [clientPlaceholder, setPlaceholder] = useState("");
  const [noWhitelist, setNoWhitelist] = useState(false);
  const [loadedNumber, setLoadedNumber] = useState(50);
  const [messageBorders, setMessageBorders] = useState([
    String(Math.floor(Math.random() * 3) + 1),
  ]);

  const updateChatSize = () => {
    if (isMobile) {
      setChatHeight(window.innerHeight - 63);
    } else {
      setChatHeight(window.innerHeight - 175);
    }
    setChatWidth(window.innerWidth - 10);
  };

  const resizeChat = (height) => {
    setTextAreaSize(height);
    setTimeout(() => {
      scrollIntoView();
    });
  };

  const sendGptMessage = async (e) => {
    if (userNftData.convoArray.length < MESSAGE_LIMIT * 2 + 2) {
      if (formValue && !clientPlaceholder) {
        firebase.analytics().logEvent("share", {
          content_type: "send message",
          item_id: nftId + formValue,
        });
        e.preventDefault();
        setPlaceholder(formValue);
        sendMessage({ nftId: nftId, message: formValue })
          .then((result) => {
            setPlaceholder("");
            if (result.data.status == "Not On Whitelist") {
              setNoWhitelist(true);
            } else if (result.data.status == "GPT Budget is Over") {
              console.log(" WE BROKE AS SHIT");
            }
          })
          .catch((error) => console.log("FAILED SEND MESSAGE", error));
        setFormValue("");
        scrollIntoView();
      }
    } else {
      firebase.analytics().logEvent("select_content", {
        content_type: "View Share Popup",
        item_id: nftId,
      });
      setShowPopup(true);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      sendGptMessage(e);
    }
  };

  const deleteMessage = async (id, tag, message) => {
    // await userNftMessagesRef.doc(id).delete();
    // await userNftDocRef.update({
    //   currentConvo: firebase.firestore.FieldValue.arrayRemove(
    //     tag + ": " + message
    //   ),
    // });
  };

  function Typing(props) {
    return (
      <div className="flex justify-start py-1">
        <img
          className="w-9 h-9 sm:w-12 sm:h-12 rounded-full ml-1 mr-1 headshot"
          src={props.image}
        />
        <div className="break-words rounded-2xl max-w-xs  sm:max-w-lg  bg-gray-300 sm:bg-gray-300 inline-block text-xl ml-1 leading-little bot1 pb-.5">
          <BeatLoader size={3.5} speedMultiplier={0.75} color="#111827" />
        </div>
      </div>
    );
  }

  if (chatHeight == 0) {
    updateChatSize();
  }

  function scrollIntoView() {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  // useEffect(() => {
  //   bottomRef.current.scrollIntoView({ behavior: "smooth" });
  // }, [messages]);

  if (noWhitelist) {
    return (
      <div>
        <BackButton />
        <p className="text-6xl">NOT ON WHITELIST</p>
      </div>
    );
  }

  let messageDoms = [];

  if (
    userNftData &&
    userNftData.convoArray &&
    userNftData.convoArray.length > 1
  ) {
    if (userNftData.convoArray.length > messageBorders.length) {
      var copy = messageBorders;
      while (copy.length < userNftData.convoArray.length) {
        copy.push(String(Math.floor(Math.random() * 3) + 1));
      }
      setMessageBorders(copy);
    }

    let current;
    var c = userNftData.convoArray.length - 1;
    while (c > 0 && c > userNftData.convoArray.length - loadedNumber) {
      current = userNftData.convoArray[c];
      messageDoms.push(
        <ChatMessage
          className="w-200"
          key={c}
          message={current.slice(1)}
          author={current.slice(0, 1)}
          nftImage={userNftData && userNftData.headshot}
          userImage={userData && userData.image}
          randomBorder={messageBorders[c]}
          onDelete={deleteMessage}
        />
      );
      c--;
    }

    if (clientPlaceholder) {
      messageDoms.unshift(
        <ChatMessage
          key="placeholder"
          message={clientPlaceholder}
          author="H"
          className="w-200"
          userImage={userData && userData.image}
          randomBorder={messageBorders[c]}
        />
      );
      messageDoms.unshift(
        <Typing key="typing" image={userNftData && userNftData.headshot} />
      );
      setTimeout(() => {
        scrollIntoView();
      });
    }

    messageDoms.unshift(<span ref={bottomRef} key="bottom ref"></span>);
  }

  function loadMore() {
    setLoadedNumber(loadedNumber + 50);
  }

  return (
    <div className="flex justify-around">
      {showPopup && (
        <SharePopup
          close={() => setShowPopup(false)}
          navigateHome={() => {
            navigate("/");
          }}
          name={userNftData && userNftData.name}
        />
      )}
      <div className="relative">
        {userNftData && userNftData.name == "Dr. Talos" ? (
          <img
            className="absolute invisible xl:visible -left-80 xl:-left-92 2xl:-left-100 top-28 h-132 w-60 pr-4"
            src={userNftData && userNftData.image}
          />
        ) : (
          <img
            className="absolute invisible xl:visible -left-80 xl:-left-92 2xl:-left-100 top-16 w-64 pr-4"
            src={userNftData && userNftData.image}
          />
        )}
        <div className="flex justify-around">
          {" "}
          <p className="absolute  top-1 sm:-top-11 text-5xl sm:text-8xl">
            {userNftData && userNftData.name}
          </p>
        </div>
        <div className="flex justify-between  items-end">
          <BackButton />
          <InfoButton nftId={nftId} />
        </div>

        <div className="">
          <div
            className="bg-gray-100 rounded-xl  sm:max-w-2xl sm:w-176 flex flex-col shadow-2xl"
            style={{ height: chatHeight, width: chatWidth }}
          >
            {userNftData && userNftData.convoArray && (
              <p className="text-center text-xl   rounded-t-xl bg-slate h-6">
                {Math.max(
                  Math.round(
                    (MESSAGE_LIMIT * 2 + 1 - userNftData.convoArray.length) / 2
                  ),
                  0
                )}{" "}
                messages left
              </p>
            )}

            <div
              className="text-clip flex-auto  "
              style={{ height: chatHeight - textAreaSize - 200 }}
            >
              <div
                id="scrollableDiv"
                className="scrollbar-hide"
                style={{
                  maxHeight: chatHeight - textAreaSize - 40,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  dataLength={loadedNumber}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  inverse={true} //
                  next={loadMore}
                  hasMore={true}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {messageDoms}
                </InfiniteScroll>
              </div>
            </div>
            <div>
              <form
                className="flex justify-end items-center rounded-b-lg mt-1 mb-1 pb-1 pl-1"
                onSubmit={sendGptMessage}
              >
                <TextareaAutosize
                  className="w-full text-xl mr-2 pl-2 pr-1 bg-gray-200 rounded-lg resize-none border-gray-400 border-2 pb-1 scrollbar-hide"
                  minRows={1}
                  maxRows={5}
                  value={formValue}
                  onChange={(e) => {
                    setFormValue(e.target.value);
                  }}
                  onHeightChange={resizeChat}
                  placeholder="Write message..."
                  maxLength={300}
                  onKeyDown={onEnterPress}
                  autoFocus={!isMobile}
                />

                <img
                  src={sendButton}
                  className="w-14 h-10 cursor-pointer pr-1 rounded-br-lg"
                  onClick={sendGptMessage}
                  draggable="false"
                  onMouseDown={(e) => {
                    if (!isMobile) {
                      e.currentTarget.src = sendButtonFocus;
                    }
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.src = sendButtonHover;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.src = sendButton;
                  }}
                  onTouchStart={(e) => {
                    e.currentTarget.src = sendButtonHover;
                  }}
                  onTouchEnd={(e) => {
                    e.currentTarget.src = sendButton;
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        {/* <button onClick={createImage}>Share</button> */}
      </div>
    </div>
  );
}

export default ChatRoom;
