// This is Firebase Prod
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/functions";
import "firebase/storage";

let config = {
  apiKey: "AIzaSyAspVgMAmiwqmDoCikiilasfkkRDdBmrE0",
  authDomain: "nft-convo.firebaseapp.com",
  projectId: "nft-convo",
  storageBucket: "nft-convo.appspot.com",
  messagingSenderId: "110875683372",
  appId: "1:110875683372:web:449368529c7f87a503ff89",
  measurementId: "G-P4Q7GTKL4F",
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;
