import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

import firebase from "./Firebase.js";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import chatButton from "./assets/ChatButton.png";
import chatButtonHover from "./assets/ChatButtonHover.png";
import chatButtonFocus from "./assets/ChatButtonFocus.png";
import infoButton from "./assets/InfoButton.png";
import Menu from "./FirstMenu.js";
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";
const storage = firebase.storage();

const auth = firebase.auth();
const firestore = firebase.firestore();
function FirstMenu() {
  const uid = auth.currentUser ? auth.currentUser.uid : "a";
  const [emailForm, setEmailForm] = useState(
    auth.currentUser ? auth.currentUser.email : ""
  );
  const [nameForm, setNameForm] = useState("");
  const [promoCheck, setPromoCheck] = useState(false);
  const userDocRef = firestore.collection("users").doc(uid);
  const [userData] = useDocumentData(userDocRef);
  const promoChange = (e) => {
    if (e.target.checked) {
      setPromoCheck(true);
    } else {
      setPromoCheck(false);
    }
  };

  const updatePreferences = async (e) => {
    var batch = firestore.batch();
    if (emailForm && emailForm.trim() != "") {
      batch.update(userDocRef, {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        email: emailForm,
      });
    }
    if (nameForm && nameForm.trim() != "") {
      batch.update(userDocRef, {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        name: nameForm,
      });
    }
    if (promoCheck) {
      batch.update(userDocRef, {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        receivePromos: true,
      });
    }

    await batch.commit().catch((e) => {
      if (e.code === "permission-denied") {
        console.log("Permission Denied");
      }
    });
  };

  console.log("NAME FORM", nameForm);

  return (
    <div className="flex justify-around">
      <div className="border-2 border-black bg-gray-200 w-96 p-6  z-10">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updatePreferences();
          }}
        >
          <p className="text-2xl mb-2">Preferences</p>
          <img
            className="w-20 h-20 rounded-full content-center m-auto headshot"
            src={userData && userData.image}
            draggable="false"
          />
          <p className="mt-1 text-lg">
            Nickname:<span className="text-red-500 font-sans"> *</span>
          </p>
          <input
            autoFocus
            className="border-2 mb-1 border-black w-full  mr-2 px-1 text-xl"
            value={nameForm}
            onChange={(e) => {
              setNameForm(e.target.value);
            }}
            maxLength={20}
            required
          />
          <p className="text-lg">Email:</p>
          <input
            className="border-2 mb-1 border-black w-full t mr-2 px-1 text-xl"
            value={emailForm}
            onChange={(e) => setEmailForm(e.target.value)}
            maxLength={50}
            type="email"
          />
          <label className="mb-4">
            <input
              type="checkbox"
              checked={promoCheck}
              onChange={promoChange}
            />{" "}
            I want to receive updates from Sigils
          </label>
          {nameForm ? (
            <input
              className="flex-none border-2 w-full h-12 text-2xl bg-blue-600 cursor-pointer disabled:bg-gray-900"
              value="Save"
              type="submit"
              onMouseDown={(e) => {
                e.currentTarget.style["background-color"] = "#2563eb";
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style["background-color"] = "#60a5fa";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style["background-color"] = "#3b82f6";
              }}
              onTouchStart={(e) => {
                e.currentTarget.style["background-color"] = "#60a5fa";
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style["background-color"] = "#3b82f6";
              }}
              id="realButton"
            ></input>
          ) : (
            <input
              className="flex-none border-2 w-full h-12 text-2xl bg-blue-300 cursor-pointer disabled:bg-gray-900"
              value="Save"
              type="submit"
              id="disabledButton"
            ></input>
          )}
        </form>
      </div>
    </div>
  );
}
export default FirstMenu;
