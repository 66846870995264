import BackButton from "./BackButton.js";
import caution from "./assets/Caution.png";
import acceptButton from "./assets/MediumGreen.png";
import acceptButtonHover from "./assets/MediumGreenHover.png";
import acceptButtonFocus from "./assets/MediumGreenFocus.png";
function Warning({ setWarned }) {
  return (
    <div className="flex justify-around mx-2">
      <div>
        <BackButton />
        <div className="bg-gray-100 max-w-lg rounded-xl ">
          <p className="text-3xl sm:text-5xl mx-4 text-center pt-3 text-gray-900">
            Do Not Be Fooled! All converations are with an AI, not a real
            person!
          </p>
          <p className="text-2xl mx-1"></p>
          <div className="flex flex-col  items-center">
            <img className="w-44 mt-4 mb-4" src={caution} />
            <div
              className="relative cursor-pointer w-48 mb-4 unselectable"
              onClick={() => setWarned(true)}
              onMouseDown={(e) => {
                e.currentTarget.querySelector("img").src = acceptButtonFocus;
              }}
              onMouseEnter={(e) => {
                e.currentTarget.querySelector("img").src = acceptButtonHover;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.querySelector("img").src = acceptButton;
              }}
              onTouchStart={(e) => {
                e.currentTarget.querySelector("img").src = acceptButtonHover;
              }}
              onTouchEnd={(e) => {
                e.currentTarget.querySelector("img").src = acceptButton;
              }}
            >
              <p className="text-2xl absolute right-10 top-8.5">I Understand</p>
              <img className="" src={acceptButton} draggable="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Warning;
