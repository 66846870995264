import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./App.css";

import firebase from "./Firebase.js";

import BackButton from "./BackButton.js";

import { useDocumentData } from "react-firebase-hooks/firestore";
import chatButton from "./assets/ChatButton.png";
import chatButtonHover from "./assets/ChatButtonHover.png";
import chatButtonFocus from "./assets/ChatButtonFocus.png";

import { AutoFontSize } from "auto-fontsize";
import { isMobile } from "react-device-detect";

const auth = firebase.auth();
const firestore = firebase.firestore();

function ChatPanel({ nftId, applyNftChat }) {
  let params = useParams();
  let navigate = useNavigate();

  nftId = params.nftId;

  const nftDocRef = firestore.collection("nfts").doc(nftId);
  const [nftData] = useDocumentData(nftDocRef);

  const getMaxHeight = () => {
    if (window.innerWidth > 625) {
      return window.innerHeight - 143;
    } else {
      return window.innerHeight - 31;
    }
  };

  const getWidth = () => {
    if (isMobile) {
      return window.innerWidth - 10;
    } else {
      return 672;
    }
  };
  return (
    <div className="flex justify-around ">
      <div>
        <BackButton />
        <div
          className="overflow-y-auto scrollbar-hide"
          style={{ "max-height": getMaxHeight() }}
        >
          <div
            className="bg-gray-100 rounded-xl py-10  ml-1 shadow-2xl mr-1"
            style={{ width: getWidth() }}
          >
            <div className="flex justify-around">
              {nftData && nftData.name == "Dr. Talos" ? (
                <img className="  h-88 w-44 px-2" src={nftData.image} />
              ) : (
                <img className=" w-44 px-2" src={nftData && nftData.image} />
              )}

              <div className="flex flex-col items-center">
                <p className="mt-10 text-7xl sm:text-8xl">
                  {nftData && nftData.name}
                </p>
                <div
                  className="mt-16 relative text-center cursor-pointer unselectable"
                  onClick={() => {
                    navigate(`/chat/${nftId}`);
                    firebase.analytics().logEvent("select_content", {
                      content_type: "View Chat",
                      item_id: nftId,
                    });
                  }}
                  onMouseDown={(e) => {
                    e.currentTarget.querySelector("img").src = chatButtonFocus;
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector("img").src = chatButtonHover;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector("img").src = chatButton;
                  }}
                  onTouchStart={(e) => {
                    e.currentTarget.querySelector("img").src = chatButtonHover;
                  }}
                  onTouchEnd={(e) => {
                    e.currentTarget.querySelector("img").src = chatButton;
                  }}
                >
                  <p className=" text-3xl absolute right-12.2 top-8.5 text-gray-900">
                    Say Hello
                  </p>
                  <img
                    draggable="false"
                    className="w-52 z-10"
                    src={chatButton}
                  ></img>
                </div>
              </div>
            </div>

            <div className="text-2xl mx-10 mt-4 space-y-2.5">
              {nftData && nftData.gender && (
                <p>
                  <span className="font-bold">Gender:</span>{" "}
                  <span className="">{nftData.gender}</span>
                </p>
              )}
              {nftData && nftData.personality && (
                <p>
                  <span className="font-bold">Personality:</span>{" "}
                  {nftData.personality}
                </p>
              )}
              {nftData && nftData.bio && (
                <p>
                  <span className="font-bold">Bio:</span> {nftData.bio}
                </p>
              )}
              {nftData && nftData.likes && (
                <p>
                  <span className="font-bold">Likes:</span>{" "}
                  {nftData.likes.join(", ")}
                </p>
              )}
              {nftData && nftData.dislikes && (
                <p>
                  <span className="font-bold">Dislikes:</span>{" "}
                  {nftData.dislikes.join(", ")}
                </p>
              )}
              {nftData && nftData.headAccessory && (
                <p>
                  <span className="font-bold">Head Accessory:</span>{" "}
                  {nftData.headAccessory}
                </p>
              )}
              {nftData && nftData.hairColor && (
                <p>
                  <span className="font-bold">Hair Color:</span>{" "}
                  {nftData.hairColor}
                </p>
              )}
              {nftData && nftData.hairStyle && (
                <p>
                  <span className="font-bold">Hair Style:</span>{" "}
                  {nftData.hairStyle}
                </p>
              )}
              {nftData && nftData.beard && (
                <p>
                  <span className="font-bold">Beard: </span>
                  {nftData.beard}
                </p>
              )}
              {nftData && nftData.eyeWear && (
                <p>
                  <span className="font-bold">Eye Wear:</span> {nftData.eyeWear}
                </p>
              )}
              {nftData && nftData.eyeColor && (
                <p>
                  <span className="font-bold">Eye Color:</span>{" "}
                  {nftData.eyeColor}
                </p>
              )}
              {nftData && nftData.expression && (
                <p>
                  <span className="font-bold">Expression:</span>{" "}
                  {nftData.expression}
                </p>
              )}
              {nftData && nftData.top && (
                <p>
                  <span className="font-bold">Top:</span> {nftData.top}
                </p>
              )}
              {nftData && nftData.bottom && (
                <p>
                  <span className="font-bold">Bottom:</span> {nftData.bottom}
                </p>
              )}
              {nftData && nftData.outerwear && (
                <p>
                  <span className="font-bold">Outerwear:</span>{" "}
                  {nftData.outerwear}
                </p>
              )}
              {nftData && nftData.outfit && (
                <p>
                  <span className="font-bold">Outfit:</span> {nftData.outfit}
                </p>
              )}
              {nftData && nftData.socks && (
                <p>
                  <span className="font-bold">Socks:</span> {nftData.socks}
                </p>
              )}
              {nftData && nftData.shoes && (
                <p>
                  <span className="font-bold">Shoes:</span> {nftData.shoes}
                </p>
              )}
              {nftData && nftData.skinTone && (
                <p>
                  <span className="font-bold">Skin Tone:</span>{" "}
                  {nftData.skinTone}
                </p>
              )}
            </div>
          </div>
          <div className="h-10"></div>
        </div>
      </div>
    </div>
  );
}

export default ChatPanel;
