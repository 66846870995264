import { useNavigate } from "react-router-dom";
import infoButton from "./assets/InfoButton.png";
import infoButtonFocus from "./assets/InfoButtonFocus.png";
import infoButtonHover from "./assets/InfoButtonHover.png";

import firebase from "./Firebase.js";
function InfoButton(props) {
  let navigate = useNavigate();
  return (
    <img
      draggable="false"
      src={infoButton}
      className="w-14   cursor-pointer headshot"
      onClick={() => {
        navigate(`/bio/${props.nftId}`);
        firebase.analytics().logEvent("select_content", {
          content_type: "View Bio",
          item_id: props.nftId,
        });
      }}
      onMouseDown={(e) => {
        e.currentTarget.src = infoButtonFocus;
      }}
      onMouseEnter={(e) => {
        e.currentTarget.src = infoButtonHover;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.src = infoButton;
      }}
      onTouchStart={(e) => {
        e.currentTarget.src = infoButtonHover;
      }}
      onTouchEnd={(e) => {
        e.currentTarget.src = infoButton;
      }}
    />
  );
}

export default InfoButton;
